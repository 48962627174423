import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Triangles } from "./Triangles";
import Soon from "./Soon";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

function App() {
  const [isVisible, setVisible] = useState(false);
  const [stayVisible, setStayVisible] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const [darkMode, setDarkMode] = useState(darkModeQuery.matches);

  useEffect(() => {
    setTimeout(() => setVisible(true), 0);
    setTimeout(() => setStayVisible(true), 1000);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    function handleChange() {
      setDarkMode(darkModeQuery.matches);
    }
    darkModeQuery.addListener(handleChange);
    return () => darkModeQuery.removeListener(handleChange);
  }, []);

  return (
    <Container>
      <Triangles
        isVisible={isVisible}
        stayVisible={stayVisible}
        windowSize={windowSize}
        darkMode={darkMode}
      />
      <Soon
        darkMode={darkMode}
        isVisible={isVisible}
        stayVisible={stayVisible}
      />
    </Container>
  );
}

export default App;
