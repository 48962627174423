import React from "react";
import styled from "styled-components";
import { triangleHeight, triangleWidth } from "./constants";

interface IProps {
  darkMode: boolean;
  isVisible: boolean;
  stayVisible: boolean;
}

const Container = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(-${triangleHeight}px);
  display: flex;
  width: ${triangleWidth * 4}px;
  justify-content: space-between;
`;

type LetterProps = IProps & {
  delay: number;
};

const Letter = styled.p`
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 42px;
  text-transform: uppercase;
  color: ${(p: LetterProps) => {
    if (p.isVisible) {
      return p.darkMode ? "white" : "black";
    } else {
      return p.darkMode ? "black" : "white";
    }
  }};
  transform: ${(p: LetterProps) => `translateY(${p.isVisible ? 0 : "75%"})`};
  opacity: ${(p: LetterProps) => (p.isVisible ? 1 : 0)};
  transition: ${(p: LetterProps) => {
    const timing = "cubic-bezier(0.215, 0.610, 0.355, 1.000)";
    const delay = p.stayVisible ? 0 : p.delay;
    const speeds = {
      opacity: p.stayVisible ? 0.2 : 1,
      color: p.stayVisible ? 0.2 : 4,
      transform: p.stayVisible ? 0.2 : 1
    };
    return [
      `opacity ${speeds.opacity}s ${delay}s ${timing}`,
      `color ${speeds.color}s ${delay}s ${timing},
      transform ${speeds.transform}s ${delay}s ${timing}`
    ].join(", ");
  }};
`;

export default function Soon(props: IProps) {
  return (
    <Container>
      <Letter {...props} delay={1.6}>
        S
      </Letter>
      <Letter {...props} delay={1.8}>
        o
      </Letter>
      <Letter {...props} delay={2}>
        o
      </Letter>
      <Letter {...props} delay={2.2}>
        n
      </Letter>
    </Container>
  );
}
