import React from "react";
import styled from "styled-components";
import { triangleHeight } from "./constants";

interface IPolygonProps {
  delay: number;
  speed: number;
}

const Polygon = styled.polygon`
  transition: fill ${(p: IPolygonProps) => `${p.speed}s`};
  transition-delay: ${(p: IPolygonProps) => `${p.delay}s`};
`;

interface IProps {
  width: number;
  x: number;
  y: number;
  fill: string;
  speed: number;
  delay: number;
  inverted?: boolean;
}

export default function Triangle({
  width,
  x,
  y,
  fill,
  delay,
  speed,
  inverted
}: IProps) {
  const points = inverted
    ? [
        [width, 0],
        [0, triangleHeight / 2],
        [width, triangleHeight]
      ]
    : [
        [0, 0],
        [width, triangleHeight / 2],
        [0, triangleHeight]
      ];

  return (
    <Polygon
      points={points
        .map(set => `${set[0] + width * x},${set[1] + triangleHeight * y}`)
        .join(" ")}
      fill={fill}
      delay={delay}
      speed={speed}
    />
  );
}
