import React from "react";
import styled from "styled-components";
import Triangle from "./Triangle";
import { triangleHeight, triangleWidth } from "./constants";

const TrianglesSvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

interface IProps {
  isVisible: boolean;
  stayVisible: boolean;
  windowSize: { width: number; height: number };
  darkMode: boolean;
}

export function Triangles({
  isVisible,
  stayVisible,
  windowSize,
  darkMode
}: IProps) {
  const amountX = ((): number => {
    let result = Math.ceil(windowSize.width / triangleWidth);
    return result % 2 === 0 ? result + 2 : result + 1;
  })();

  const amountY = ((): number => {
    let result = Math.ceil(windowSize.height / triangleHeight);
    return result % 2 === 0 ? result + 2 : result + 1;
  })();

  const containerWidth = amountX * triangleWidth;
  const containerHeight = amountY * triangleHeight;

  const horizontal = new Array(amountX).fill("").map((_, i) => i);
  const vertical = new Array(amountY).fill("").map((_, i) => i);

  const firstLogoPosition = { x: amountX / 2 - 1, y: amountY / 2 - 1 };

  const logoPositions = [
    firstLogoPosition,
    {
      x: firstLogoPosition.x + 1,
      y: firstLogoPosition.y + (Math.floor(amountX / 2) % 2 ? 1 : 0)
    },
    { x: firstLogoPosition.x, y: firstLogoPosition.y + 1 }
  ];

  return (
    <TrianglesSvg
      style={{ width: containerWidth, height: containerHeight }}
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}
    >
      {horizontal.map(x => {
        return vertical.map(y => {
          const isLogo =
            isVisible &&
            logoPositions.findIndex(pos => x === pos.x && y === pos.y) > -1;

          const distanceFromEdge = Math.min(
            Math.min(x / amountX, (amountX - x) / amountX),
            Math.min(y / amountY, (amountY - y) / amountY)
          );

          return (
            <>
              <Triangle
                key={`${x}-${y}-a`}
                width={triangleWidth}
                x={x}
                y={x % 2 === 0 ? y : y - 0.5}
                fill={(() => {
                  if (!isVisible) {
                    return darkMode ? "black" : "white";
                  }
                  if (isLogo) {
                    return darkMode ? "white" : "black";
                  }
                  return darkMode
                    ? `rgba(100, 100, 100, ${distanceFromEdge})`
                    : `rgba(235, 235, 235, ${distanceFromEdge})`;
                })()}
                delay={(() => {
                  if (stayVisible) {
                    return 0;
                  }
                  if (isLogo) {
                    return (x + y) / 20;
                  }
                  return (x + y) / 10;
                })()}
                speed={(() => {
                  if (stayVisible) {
                    return 0.2;
                  }
                  if (isLogo) {
                    return 5;
                  }
                  return 0.5;
                })()}
              />

              <Triangle
                key={`${x}-${y}-b`}
                width={triangleWidth}
                x={x}
                y={x % 2 === 0 ? y + 0.5 : y}
                inverted
                fill={darkMode ? "black" : "white"}
                delay={stayVisible ? 0 : (x + y) / 10}
                speed={stayVisible ? 0.2 : 0.5}
              />
            </>
          );
        });
      })}
    </TrianglesSvg>
  );
}
